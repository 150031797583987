import React, { useState, useEffect, useReducer, useContext, useMemo } from "react";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import _ from "lodash";
import { getSession, getUserId, getUserCompany, getUserType, getDist } from "../../utils/sessionHandler";
import useStyles from "./LiveStyles";
import { flatten, unflatten } from "flat";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import moment from "moment";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Modal from "../../components/Modal";
import CircularProgress from "../../components/CircularProgress";
import QuickWasher from "../../components/QuickWasher/QuickWasher";
import GroupedDetail from "../../components/GroupedDetail/GroupedDetail";
import reducer from './ProLiveReducer';
import ToolMeasure from '../../components/ToolMeasure/ToolMeasure';
import { WorkingMode } from '../../dictionaries/workingMode';
import { ProductType } from "../../dictionaries/productType";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const formatSelectObjectWas = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString, object.sectorId]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const formatNombreProgram = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {

      objectsList.push([object.id, object.nombre || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = {
  programs: [],
  products: [],
  washers: [],
  visualResults: [],
  loadingContent: true,
  showToolMeasureComponent: false,
  readingPROToAssociate: {},
  afterAssociate: {
    close: false,
    message: ""
  }
};

const Provisional = () => {
  const classes = useStyles();
  const locale = useContext(LocalizationContext);
  const groups = [
    { Description: t("ProductGroup"), position: 1, Index: 0, visible: true },
    { Description: t("ProcessGroup"), position: 2, Index: 1, visible: true },
    { Description: t("OtherGroup"), position: 3, Index: 2, visible: true },
    { Description: t("IncubationGroup"), position: 0, Index: 3, visible: true }
  ]
  const indexProductGroup = groups[0].Index
  const indexProcessGroup = groups[1].Index
  const indexOtherGroup = groups[2].Index
  const indexIncubationGroup = groups[3].Index

  const detailFields = useMemo(
    () => [
      //process group
      { label: t("WasherLabel", locale), name: "washer.toString", position: 0, groupIndex: indexProcessGroup },
      { label: t("WasherLabelBrandLabel", locale), name: "washer.brand", position: 1, groupIndex: indexProcessGroup },
      { label: t("WasherSerialNumberLabel", locale), name: "washer.serial", position: 2, groupIndex: indexProcessGroup },
      { label: t("CycleLabel", locale), name: "cycle", position: 3, groupIndex: indexProcessGroup },
      { label: t("ProgramNumberLabel", locale), name: "programNumber", position: 6, groupIndex: indexProcessGroup },
      {
        label: t("AreaLabel", locale), name: "instrumentMeasure.surfaceFormat", position: 4, valueVisibilityField: "modeId", groupIndex: indexProcessGroup,
        visibilityFieldDependencies: [
          { condition: WorkingMode.HTM01, visible: false },
          { condition: WorkingMode.ISO, visible: true }
        ]
      },
      { label: t("ToolLabel", locale), name: "tool", position: 5, groupIndex: indexProcessGroup },
      //product group
      { label: t("ProductLabel", locale), name: "product.toString", position: 0, groupIndex: indexProductGroup },
      { label: t("ProductBrandLabel", locale), name: "product.brand", position: 1, groupIndex: indexProductGroup },
      { label: t("LotLabel", locale), name: "loteProduct", position: 2, groupIndex: indexProductGroup },
      { label: t("VisualResultLabel", locale), name: "visualResultDescription", position: 3, groupIndex: indexProductGroup },
      //incubation group
      { label: t("PositionNumberLabel", locale), name: "positionNumber", position: 0, groupIndex: indexIncubationGroup },
      { label: t("StartedTimeLabel", locale), name: "startedTime", type: "dateTime", position: 1, groupIndex: indexIncubationGroup },
      { label: t("IncubatorLabel", locale), name: "incubator.toString", position: 2, groupIndex: indexIncubationGroup },
      { label: t("ProgramLabel", locale), name: "program.nombre", position: 3, groupIndex: indexIncubationGroup },
      {
        label: t("ProteinLabel", locale), name: "proteinFormat", position: 4, valueVisibilityField: "modeId", groupIndex: indexIncubationGroup,
        visibilityFieldDependencies: [
          { condition: WorkingMode.HTM01, visible: true },
          { condition: WorkingMode.ISO, visible: false }
        ]
      },
      { label: t("UserLabel", locale), name: "editUser.toString", position: 5, groupIndex: indexIncubationGroup },
      { label: t("SectorLabel", locale), name: "sector.toString", position: 6, groupIndex: indexIncubationGroup },
      //other group
      { label: t("BookNumberLabel", locale), name: "bookNumber", position: 2, groupIndex: indexOtherGroup },
      { label: t("PageNumberLabel", locale), name: "pageNumber", position: 3, groupIndex: indexOtherGroup },
      { label: t("NotesLabel", locale), name: "notes", position: 4, groupIndex: indexOtherGroup },
      { label: t("IgnoreFromIQAS", locale), name: "ignoreFromIQAS", position: 3, groupIndex: indexOtherGroup }
    ],
    [locale]
  );
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [lastTimeUpdated, setLastTimeUpdated] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailObject, setDetailObject] = useState(null);
  const [errors, setErrors] = useState([]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { programs, products, washers, visualResults, loadingContent, requiredFields } = state;

  useEffect(() => {
    Promise.all([
      request().get("/api/Program"),
      request().get(`/api/Product/GetByDistributorEncrypted?distributorId=${getDist().id}`),
      request().get(`/api/washer?CompanyId=${getUserCompany().Id}`),
      request().get(`/api/Enum/GetVisualResultsPRO`),
      request().get(`/api/Screen/GetRequiredFields?Screen=500`)
    ])
      .then(([programsData, productsData, washersData, visualResults, requiredFields]) => {
        setTimeout(() => {
          dispatch({
            type: "SET_CONTENT",
            payload: {
              programs: formatNombreProgram(programsData.data, ""),
              requiredFields: requiredFields.data,
              products: formatSelectObject(
                productsData.data.filter((p) => p.productModel.type === ProductType.Protein),
                t("ChooseAProduct", locale)
              ),
              washers: formatSelectObjectWas(
                washersData.data,
                t("ChooseAWasher", locale)
              ),
              visualResults: formatSelectObject(
                visualResults.data,
                t("ChooseAVisualResult")
              )
            },
          });
        }, 250);
      })
      .catch((error) => console.log(error.response));
  }, [locale]);

  useEffect(() => {
    if (!loadingContent) {
      setLoadingData(true);
      updateData();
    }
  }, [loadingContent, programs]);

  const updateData = () => {
    request()
      .get(`/api/ReadingPRO/getlive?UserLoggedId=${getUserId()}`)
      .then((response) => {
        const today = new Date();
        const date =
          String(today.getDate()).padStart(2, '0') +
          "-" +
          String((today.getMonth() + 1)).padStart(2, '0') +
          "-" +
          String(today.getFullYear()).padStart(4, '0');
        const time =
          String(today.getHours()).padStart(2, '0') +
          ":" +
          String(today.getMinutes()).padStart(2, '0') +
          ":" +
          String(today.getSeconds()).padStart(2, '0');
        setLastTimeUpdated(date + " " + time);
        setData(
          response.data
            .reduce((readingsList, reading) => {
              let programNameFromArr = "";
              for (let i = 0; i < programs.length; i++) {
                if (programs[i][0] === reading.programId) {
                  if (reading.incubator.incubatorDistributor.incubatorModelId === 4) {
                    programNameFromArr = "10 min.";
                  }
                  else {
                    programNameFromArr = programs[i][1];
                  }
                }
              }
              readingsList.push({
                ...reading,
                programName: programNameFromArr,
                startedTimeFormatted: df(reading.startedTime, "dateTime"),
                elapsedTime: secondsToString(diffInSeconds(reading.startedTime, today.toISOString())),//diffInMinutes(reading.startedTime, today),
                incubatorName: reading.incubator.toString
              });
              return readingsList;
            }, [])
            .map((x) =>
              _.mapKeys(x, (value, key) => {
                return "ReadingPRO." + key;
              })
            )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  };

  const diffInSeconds = (dt1, dt2) => {
    return moment(dt2).diff(moment.utc(dt1, "YYYY-MM-DD HH:mm:ss"), "seconds");
  };

  function secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    hour = (hour < 10) ? '0' + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? '0' + minute : minute;
    var second = seconds % 60;
    second = (second < 10) ? '0' + second : second;
    return hour + ':' + minute + ':' + second;
  };


  const getDetail = (r) => {
    setLoadingDetail(true);
    request()
      .get(`/api/ReadingPRO/getDetail?Id=${r["ReadingPRO.id"]}`)
      .then((response) =>
        setTimeout(() => {
          setLoadingDetail(false);
          const row = data.find(x => x["ReadingPRO.id"] === response.data.id)
          if(row["ReadingPRO.modeId"] == WorkingMode.ISO && row["ReadingPRO.instrumentMeasure"]){
            const instrumento = row["ReadingPRO.instrumentMeasure"].instrument
            response.data.tool = instrumento.code + " - " + instrumento.description;
          }
          setDetailObject(flatten(response.data));
        }, 500)
      );
  };

  const RequiredFieldMessage = t("RequiredFieldError") || "This field is required";

  const validateReading = (reading, formFields) => {
    let requiredError = formFields.reduce(
      (errorBag, field) => {
        (
          _.get(reading, field.propOnObject) === undefined ||
          _.get(reading, field.propOnObject) === "" ||
          _.get(reading, field.propOnObject) === null
        )
          && formFields.find(x => x.propOnObject == field.propOnObject).required
          && (errorBag[field.propOnObject] = RequiredFieldMessage)
        return errorBag;
      }, {}
    )

    if (Object.keys(requiredError).length > 0) {
      setErrors(requiredError)
    } else {
      return true;
    }
  }

  const clearMessageValidation = () => {
    setErrors([]);
  }

  const showModalToolMeasure = (object, formFields) => {
    if (validateReading(object, formFields)) {
      setErrors([]);
      dispatch({ type: "SET_READING_TO_ASSOCIATE", payload: { readingPROToAssociate: object } })
      dispatch({ type: 'SHOW_TOOL_MEASURE' })
    }
  }

  const handleAfterAssociate = (serverResponse, updatedObject) => {
    dispatch({ type: 'HIDE_TOOL_MEASURE' })
    setLoadingData(true);
    dispatch({
      type: 'SET_AFTER_ASSOCIATE',
      payload: {
        afterAssociate:
        {
          executeAction: true,
          message: serverResponse.message,
          updatedObject: updatedObject,
          type: serverResponse.result
        }
      }
    })
    dispatch({ type: 'SET_AFTER_ASSOCIATE', payload: { afterAssociate: { executeAction: false } } })
  }

  const closeModalToolMeasure = () => {
    dispatch({ type: 'HIDE_TOOL_MEASURE' })
  }

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <CRUD
        counterDown={{
          initialMinute: 0,
          initialSeconds: 10,
          refresh: updateData,
          onceTime: false
        }}
        loadingData={loadingData}
        newOption={false}
        detailOption={false}
        deleteOption={false}
        multiSelect={true}
        data={data}
        order={{ dir: "desc", orderBy: "ReadingPRO.readNumber" }}
        url={"/api/ReadingPRO"}
        massiveURL={"/api/ReadingPRO/masiveModify"}
        updateData={updateData}
        showModalToolMeasure={showModalToolMeasure}
        clearMessageValidation={clearMessageValidation}
        errorsFromValidation={errors}
        afterAssociate={state.afterAssociate}
        tableHeader={`${t("LiveTableHeader", locale)} ${lastTimeUpdated}`}
        viewedMark={true}
        tableActions={[
          {
            icon:
              "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
            tooltip: t("DetailTooltip"),
            action: getDetail,
          },
        ]}
        columns={[
          "ReadingPRO.readNumber",
          "ReadingPRO.positionNumber",
          "ReadingPRO.startedTimeFormatted",
          "ReadingPRO.elapsedTime",
          "ReadingPRO.programName",
          "ReadingPRO.visualResultDescription",
          "ReadingPRO.incubatorName",
        ]}
        headers={[
          t("ReadNumberLabel", locale),
          t("PositionNumberLabel", locale),
          t("StartedTimeLabel", locale),
          t("ElapsedTimeLabel", locale),
          t("ProgramLabel", locale),
          t("VisualResultLabel", locale),
          t("IncubatorLabel", locale),
        ]}
        idField={"ReadingPRO.id"}
        grouped={groups}
        useFieldMap={false}
        formFields={[
          {
            position: 0,
            groupIndex: 0,
            label: t("ProductLabel", locale),
            propOnObject: "ReadingPRO.productId",
            propOnMassiveObject: "ReadProMasive.productId",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: products,
            required: requiredFields.find(x => x.objectName === 1531) ? true : false
          },
          {
            position: 1,
            groupIndex: 0,
            label: t("LotLabel", locale),
            propOnObject: "ReadingPRO.loteProduct",
            propOnMassiveObject: "ReadProMasive.loteProduct",
            class: "col-12 col-md-6 px-0",
            required: requiredFields.find(x => x.objectName === 1545) ? true : false
          },
          {
            position: 0,
            groupIndex: 2,
            label: t("BookNumberLabel", locale),
            propOnObject: "ReadingPRO.bookNumber",
            propOnMassiveObject: "ReadProMasive.bookNumber",
            class: "col-12 col-md-6 px-0",
            required: requiredFields.find(x => x.objectName === 1555) ? true : false
          },
          {
            position: 0,
            groupIndex: 2,
            label: t("PageNumberLabel", locale),
            propOnObject: "ReadingPRO.pageNumber",
            propOnMassiveObject: "ReadProMasive.pageNumber",
            class: "col-12 col-md-6 px-0",
            required: requiredFields.find(x => x.objectName === 1565) ? true : false
          },
          {
            position: 1,
            groupIndex: indexProcessGroup,
            label: t("CycleLabel", locale),
            propOnObject: "ReadingPRO.cycle",
            propOnMassiveObject: "ReadProMasive.cycle",
            class: "col-12 col-md-6 px-0",
            type: "specialInput",
            required: requiredFields.find(x => x.objectName === 1575) ? true : false
          },
          {
            position: 2,
            groupIndex: indexProcessGroup,
            label: t("ProgramNumberLabel", locale),
            propOnObject: "ReadingPRO.programNumber",
            propOnMassiveObject: "ReadProMasive.programNumber",
            class: "col-12 col-md-6 px-0"
          },
          {
            position: 3,
            groupIndex: indexProcessGroup,
            label: t("ToolLabel", locale),
            visibleCondition: ["ReadingPRO.modeId", "=", "0"],
            propOnObject: "ReadingPRO.tool",
            propOnMassiveObject: "ReadProMasive.tool",
            class: "col-12 col-md-6 px-0",
            required: requiredFields.find(x => x.objectName === 1610) ? true : false
          },
          {
            position: 0,
            groupIndex: indexProcessGroup,
            label: t("WasherLabel", locale),
            propOnObject: "ReadingPRO.washerId",
            propOnMassiveObject: "ReadProMasive.washerId",
            component: <a style={{ fontSize: 11 }} onClick={(e) => {
              e.preventDefault()
              dispatch({ type: 'SHOW_ABM_WASHER' })
            }} href="#">{t("AddWasher", locale)}</a>,
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: washers,
            id: "machine",
            userType: getUserType(),
            defaultValue: state.selectedWasher,
            required: requiredFields.find(x => x.objectName === 1595) ? true : false
          },
          {
            position: 2,
            groupIndex: 0,
            label: t("VisualResultLabel", locale),
            propOnObject: "ReadingPRO.visualResult",
            propOnMassiveObject: "ReadProMasive.visualResult",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: visualResults,
            required: requiredFields.find(x => x.objectName === 1636) ? true : false
          },
          {
            position: 0,
            groupIndex: 2,
            label: t("IgnoreFromIQAS", locale),
            propOnObject: "ReadingPRO.ignoreFromIQAS",
            propOnMassiveObject: "ReadProMasive.ignoreFromIQAS",
            class: "col-12 col-md-6 px-0",
            type: "checkbox",
            required: false
          },
          {
            position: 0,
            groupIndex: 2,
            label: t("NotesLabel", locale),
            propOnObject: "ReadingPRO.notes",
            propOnMassiveObject: "ReadProMasive.notes",
            class: "col-12 px-0",
            required: requiredFields.find(x => x.objectName === 1585) ? true : false
          }
        ]}
        formFieldsMachine={[washers]}
        baseObject={{
          ReadingPRO: {
            productId: null,
            loteProduct: null,
            bookNumber: null,
            pageNumber: null,
            cycle: null,
            washerId: null,
            visualResult: null,
            notes: null,
          }
        }}
        massiveBaseObject={{
          ReadProMasive: {
            ReadIds: [],
            productId: null,
            loteProduct: null,
            bookNumber: null,
            pageNumber: null,
            cycle: null,
            washerId: null,
            visualResult: null,
            notes: null,
          },
          UserLogged: getSession(),
        }}
        method={"put"}
      />

      {(loadingDetail || detailObject) && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{`${t("Title", locale)} ${detailObject ? `(${detailObject.readNumber})` : ""
                }`}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => setDetailObject(null)}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => setDetailObject(null)}
          responsive
        >
          {!detailObject && (
            <div className={classes.detailLoading}>
              <CircularProgress />
            </div>
          )}
          {detailObject && <GroupedDetail groups={groups} detailFields={detailFields} detailObject={detailObject}></GroupedDetail>}
        </Modal>
      )}
      {state.abmWasherVisible && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{t("AddWasher", locale)}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => dispatch({ type: 'HIDE_ABM_WASHER' })}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => dispatch({ type: 'HIDE_ABM_WASHER' })}
        >
          <QuickWasher onClickCallBack={(e) => {
            const newWasher = [e.Id, e.Name, getSession().SectorId]
            washers.push(newWasher);
            dispatch({ type: 'ADD_WASHER', payload: { Washers: washers, Selected: e.Id } })
          }}></QuickWasher>
        </Modal>
      )}
      {state.showToolMeasureComponent && (
        <ToolMeasure
          readingPRO={state.readingPROToAssociate}
          method={"put"}
          url={"/api/ReadingPRO"}
          handleAfterAssociate={handleAfterAssociate}
          closeModalToolMeasure={closeModalToolMeasure}
        >
        </ToolMeasure>
      )}
    </div>
  );
};

export default Provisional;